<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.products_in_business_units_long')">
            <b-row >
                <div class="table-container mx-1">
                    <table>
                        <thead>
                            <tr ref="firstRow" class="p-05">
                                <th ref="firstCol" class="p-05 fix width-200">
                                    <div class="header"><div>{{ $t('keys.products_in_business_units.product_code') }}</div><div class="divider-table"></div></div>
                                </th>
                                <th class="p-05 fix width-200">
                                    <div class="header"><div>{{ $t('form_data.name') }}</div><div class="divider-table"></div></div>
                                </th>
                                <th class="p-05 fix" v-for="business_unit in business_units" :key="business_unit.id"><div class="header">{{ business_unit.name }}<div class="divider-table"></div></div></th>
                            </tr>
                            <tr class="second-row p-05">
                                <th class="p-05 fix">
                                    <b-form-input :debounce="500" v-model="searchCode" @update="searchProducts" />
                                </th>
                                <th class="p-05 fix">
                                    <b-form-input :debounce="500" v-model="searchName" @update="searchProducts" />
                                </th>
                                <th class="p-05 fix" v-for="business_unit in business_units" :key="business_unit.id"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="p-05" v-for="product in productsSearch" :key="product.id" :class="{'disabled-product text-gray disabled-checkbox': product.disabled}" >
                                <td class="p-05 fix width-200"><div>{{ product.product_code }}</div></td>
                                <td class="p-05 fix width-200" :id="product.id"><div>{{ product.name }} </div><feather-icon v-if="product.disabled" icon="InfoIcon" /></td>
                                <td class="p-05" v-for="business_unit in business_units" :key="`${business_unit.id}}`">
                                    <b-form-checkbox :disabled="product.disabled || !$hasPermission($permissions.ProductsWrite)" v-model="businessUnitMap[business_unit.id][product.id].enabled_in_bussines_unit" @change="(val) => editEnabled(val, product, business_unit)" />
                                </td>
                                <b-tooltip custom-class="disabled-tooltip" v-if="product.disabled" :target="product.id">
                                    {{$t('keys.products_in_business_units.disabling_unavailable')}}.
                                </b-tooltip>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-row>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BCard, BOverlay, BRow, BFormCheckbox, BTooltip, BFormInput} from 'bootstrap-vue'
  
    export default {
        components: {BRow, BCard, BOverlay, BFormCheckbox, BTooltip, BFormInput},
        data() {
            return {
                productsRaw: [],
                showLoader: false,
                arraysLoad: false,
                business_units: [],
                disabledProducts: [],
                businessUnitMap: {},
                productMap: {},
                searchCode: '',
                searchName: '',
                productsSearch: []
            }
        },
        methods: {
            searchProducts() {
                if (this.searchCode === '' && this.searchName === '') {
                    this.productsSearch = this.productsRaw
                    return
                }
                const code = this.searchCode.toLowerCase()
                const name = this.searchName.toLowerCase()
                this.productsSearch = this.productsRaw.filter(x => x.product_code.toLowerCase().includes(code) && x.name.toLowerCase().includes(name))
                this.setCssVariables()
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/products')
                    const response2 = this.$http.get('/api/client/v1/business_units/')
                    const response3 = this.$http.get('/api/client/v1/products/disabled_in_business_units')

                    const responses = await Promise.all([response1, response2, response3])
                    this.productsRaw = responses[0].data ?? []                    
                    this.business_units = responses[1].data ?? []
                    this.disabledProducts = responses[2].data ?? []

                    this.productsRaw = this.productsRaw.map((item) => {
                        return {...item, enabled_in_bussines_unit: true}
                    })

                    for (const p of this.productsRaw) {
                        this.productMap[p.id] = p
                    }

                    for (const b of this.business_units) {
                        this.businessUnitMap[b.id] = JSON.parse(JSON.stringify(this.productMap))
                    }

                    for (const b of this.disabledProducts) {
                        for (const p of this.productsRaw) {
                            if (b.disabled_products && b.disabled_products.includes(p.id)) {
                                if (!this.businessUnitMap[b.business_unit_id] || !this.businessUnitMap[b.business_unit_id][p.id]) {
                                    continue
                                }

                                if (this.businessUnitMap[b.business_unit_id][p.id].enabled_in_bussines_unit) {
                                    this.businessUnitMap[b.business_unit_id][p.id].enabled_in_bussines_unit = false
                                }
                            }
                            
                        }
                    }

                    this.productsSearch = this.productsRaw

                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async editEnabled(checked, product, business_unit) {

                if (!this.$hasPermission(this.$permissions.ProductsWrite)) {
                    return
                }

                try {
                    this.showLoader = true

                    const data = {
                        'business_unit_id': business_unit.id,
                        'disabled_product_id': product.id,
                        'status': checked
                    }

                    await this.$http.post('/api/client/v1/products/disabled_in_business_units', data)

                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                    this.businessUnitMap[business_unit.id][product.id].enabled_in_bussines_unit = !this.businessUnitMap[business_unit.id][product.id].enabled_in_bussines_unit
                } finally {
                    this.showLoader = false
                }                
            },
            setCssVariables() {
                this.$nextTick(() => {
                    const firstRow = this.$refs.firstRow
                    if (firstRow) {
                        const firstRowHeight = `${firstRow.offsetHeight}px`

                        // Set the CSS variable with the height of the first row
                        document.documentElement.style.setProperty('--first-row-height', firstRowHeight)
                    }
                })

                this.$nextTick(() => {
                    const firstCol = this.$refs.firstCol
                    if (firstCol) {
                        const firstColWidth = `${firstCol.offsetWidth}px`

                        // Set the CSS variable with the height of the first row
                        document.documentElement.style.setProperty('--first-col-width', firstColWidth)
                    }
                })
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true

            this.setCssVariables()

            addEventListener('resize', this.setCssVariables)
        },
        beforeDestroy() {
            removeEventListener('resize')
        }
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>

.table-container {
    overflow: auto;
    width: 100%;
    height: 62vh;
    scrollbar-width: thin;
    -ms-overflow-style: auto;
    border-left: 1px solid #babfc7;
    border-top: 1px solid #babfc7;
}

.dark-layout .table-container {
    border: none;
}

table {
    width: 100%;
}

thead, tbody tr {
    table-layout: fixed;
}

th, td {
    min-width: 150px;
    text-align: left;
}

th div, td div {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

th {
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
}

.dark-layout th, .dark-layout td {
    text-align: left;
    background-color: #535353;
}

.fix{
    z-index: 5;
}

.dark-layout .fix{
    background-color: #4b4b4b;
}

.dark-layout thead tr:nth-child(1), .dark-layout thead tr:nth-child(2) {
    box-shadow: 0 0 1px 3px #4b4b4b;
}

thead tr:nth-child(2) {
    box-shadow: 0 0 1px 2px #babfc7;
}

td:nth-child(1),
td:nth-child(2) {
    position: sticky;
    left: 0;
}

th:nth-child(1),
th:nth-child(2) {
    position: sticky;
    left: 0;
    z-index: 7;
}

@media only screen and (max-width: 600px) {
    td:nth-child(1),
    td:nth-child(2) {
        position: unset!important;
    }

    th:nth-child(1),
    th:nth-child(2) {
        position: unset!important;
    }
}

td:nth-child(2) {
    position: sticky;
    left: var(--first-col-width);
}

th:nth-child(2) {
    position: sticky;
    left: var(--first-col-width);
}

.second-row {
    position: sticky;
    top: var(--first-row-height)!important;
    z-index: 7;
}

.disabled-product {
    background: #c44569!important;
}

.disabled-product td {
    background: #c44569!important;
    color: white;
}

.dark-layout .disabled-product {
    color: black;
}

.width-200 {
    max-width: 200px;
}

.p-05 {
    padding: 0.75rem!important;
}

tbody tr:nth-child(even) td {
    background-color: #f8f8f8;
}


tbody td {
    box-shadow: 0 -1px 0 0 #dde2eb inset, 0 -1px 0 0 #dde2eb;
}

.dark-layout tbody td {
    box-shadow: none;
}

tbody tr:nth-child(odd) td {
    background-color: white;
}

.dark-layout tbody tr:nth-child(even) td {
    background-color: #343434;
}

.dark-layout tbody tr:nth-child(odd) td {
    background-color: #535353;
}

.divider-table {
    position: absolute;
    right: 0;
    border-right: 1px solid rgba(104, 104, 110, 0.5);
    top: 40%;
    height: 14px;
}

thead th:last-child .divider-table {
    border: none;
}

.header {
    font-size: 13px;
    font-weight: 700;
}
</style>
<style>
.disabled-checkbox .custom-control-input:checked ~ .custom-control-label::before, .disabled-checkbox .custom-control-input ~ .custom-control-label::before {
    background-color: rgba(255,255,255, 0.3)!important;
}

.disabled-tooltip .arrow {
    display: none!important;
}
</style>